import React from 'react'
import LibraryWrapper from './LibraryWrapper'
import { Section, Typography } from '../../components/library'

function section() {
    return (
        <LibraryWrapper>
            <Section title="Medication">
                <Typography variant="body1" align="left">
                    Lorem ipsum dolor sit amet, consectetur adipisicing elit. Quos blanditiis tenetur
                    unde suscipit, quam beatae rerum inventore consectetur, neque doloribus, cupiditate numquam {' '}
                    <strong>dignissimos laborum fugiat deleniti? Eum quasi quidem quibusdam</strong>.
                </Typography>
            </Section>


        </LibraryWrapper>
    )
}
export default section
